import { Injectable } from '@angular/core';
import {
  BankCustomizationKeys,
  BankCustomizationServiceProviders,
  BankCustomizationServiceProvidersEnum,
  BankFeatures,
  BankLetterShippingProviders,
  BankTargetGroupAnalysisProviders,
  MfBankCustomizationType,
  MfBankCustomizationValueType,
} from '@app/shared/bank-customization/bank-customization.type';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { Maybe } from '@shared/types/util.types';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MfBankCustomizationService {
  readonly LOCAL_STORAGE_KEY: string = 'bank-customization';
  private internalData: MfBankCustomizationType = {};

  public updates$: Subject<MfBankCustomizationType> = new Subject<MfBankCustomizationType>();

  constructor() {
    this.getFromLocalStorage();
  }

  get data(): MfBankCustomizationType {
    return this.internalData;
  }

  clear(): void {
    this.setCompleteDataFromString('{}');
  }

  get(key: string): MfBankCustomizationValueType {
    return this.internalData[key];
  }

  getFeatures(): MfBankCustomizationValueType {
    return this.get(BankCustomizationKeys.FEATURES);
  }

  getServiceProviders(): BankCustomizationServiceProviders | null {
    const serviceProviders = this.get(BankCustomizationKeys.SERVICE_PROVIDERS);

    if (!serviceProviders || typeof serviceProviders !== 'object') {
      return null;
    }

    return serviceProviders as BankCustomizationServiceProviders;
  }

  getLetterShippingProvider(): BankLetterShippingProviders | undefined {
    return this.getServiceProviders()?.[
      BankCustomizationServiceProvidersEnum.LETTER_SHIPPING_PROVIDER
    ];
  }

  getTargetGroupAnalysisProvider(): BankTargetGroupAnalysisProviders | undefined {
    return this.getServiceProviders()?.[
      BankCustomizationServiceProvidersEnum.TARGET_GROUP_ANALYSIS_PROVIDER
    ];
  }

  can(feature: BankFeatures): boolean {
    return this.getEnabledFeatures().includes(feature);
  }

  canOneOf(...features: BankFeatures[]): boolean {
    for (const feature of features) {
      if (this.can(feature)) {
        return true;
      }
    }

    return false;
  }

  getEnabledFeatures(): BankFeatures[] {
    const features = this.getFeatures();

    if (!features) {
      return [];
    }

    return Object.entries(features)
      .filter(([_, value]) => !!value)
      .map(([key]) => key as BankFeatures);
  }

  setCompleteDataFromString(value: Maybe<string>): void {
    let parsedValue: MfBankCustomizationType = {};
    try {
      if (value) {
        parsedValue = JSON.parse(value) || {};
      }
    } catch {
      parsedValue = {};
    }

    this.updateStoredValue(parsedValue);
  }

  private updateStoredValue(value: MfBankCustomizationType): void {
    this.internalData = value;

    this.updateFeatureContext();

    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(value));
    this.updates$.next(this.internalData);
  }

  private updateFeatureContext(): void {
    SentryConfig.setFeaturesContext(this.getFeatures());
  }

  getFromLocalStorage(): void {
    this.setCompleteDataFromString(localStorage.getItem(this.LOCAL_STORAGE_KEY));
  }
}
