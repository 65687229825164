import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { NgClass } from '@angular/common';
import {
  Attribute,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  booleanAttribute,
  inject,
  numberAttribute,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HTML_INPUT_TOOLBAR_CONFIGS } from '@app/form/field/html-input/html-input.const';
import {
  MfHtmlInputFeatures,
  MfHtmlInputPlaceholder,
  MfHtmlInputPlaceholderConstant,
  MfHtmlInputPlaceholderGroup,
  MfHtmlInputToolbarFeatureConfigTypes,
  MfHtmlInputToolbarFeaturesConfig,
} from '@app/form/field/html-input/html-input.types';
import { MfFormHtmlInputPlaceholderSelectComponent } from '@app/form/field/html-input/placeholder-select/placeholder-select.component';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { MaterialModule } from '@app/material/material.module';
import { MfDialogService } from '@app/shared/dialog/service/dialog.service';
import { fadeToggle } from '@app/shared/util/animations/fade-toggle';
import { StringUtil } from '@app/shared/util/string.util';
import { MfBankColorThemeService } from '@shared/bank/bank-color-theme/bank-color-theme.service';
import { DEFAULT_FONT_SIZE_OPTIONS } from '@shared/config/font-size.config';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import * as QuillNamespace from 'quill';
import { Quill as QuillEditor } from 'quill';
import getPlaceholderModule from 'quill-placeholder-module';

const Quill: any = QuillNamespace;

Quill.register(
  'modules/placeholder',
  getPlaceholderModule(Quill, {
    className: 'ql-placeholder-content',
  })
);

const whitelistedFontSizes: number[] = [...DEFAULT_FONT_SIZE_OPTIONS, 6];

/* TODO: This currently updates the whitelist for all currently visible editors. Ideally we don't want this to happen, as in the
    current implementation non-visible font sizes are valid if they are just copy-pasted in from another editor (for example 6px,
    7px and 11px). It would be more ideal if we could directly change the whilelist a single editor, but as for as i know there
    is no way to do that.
*/
const Size: any = Quill.import('attributors/style/size');
Size.whitelist = whitelistedFontSizes.map((size: number) => `${size}px`);
Quill.register(Size, true);

const Link: any = Quill.import('formats/link');

class CustomLink extends Link {
  static sanitize(url: string): string {
    let value: string = super.sanitize(url);

    if (value && !value.startsWith('https://')) {
      if (value.startsWith('http://')) {
        value = value.replace('http://', 'https://');
      } else {
        return `https://${value}`;
      }
    }

    return value;
  }
}

Quill.register(CustomLink, true);

@Component({
  selector: 'mf-form-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.scss'],
  animations: [fadeToggle],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    MaterialModule,
    QuillEditorComponent,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
  ],
})
export class MfFormHtmlInputComponent extends MfFormAbstractFieldComponent implements OnInit {
  @Input() label?: string;
  @Input() hintLabel?: string;
  @Input() patternName?: string;
  @Input() placeholder: string;
  @Input() warningLabel?: string;
  @Input() toolbarFeaturesConfig!: MfHtmlInputToolbarFeaturesConfig;
  @Input() placeholderItems?: MfHtmlInputPlaceholderGroup[];
  @Input() toolbarFeaturesType: MfHtmlInputToolbarFeatureConfigTypes = 'default';
  @Input({ transform: numberAttribute }) lengthWarning?: number | undefined = undefined;
  @Input({ transform: numberAttribute }) maxLengthHint?: number = undefined;
  @Input({ transform: booleanAttribute }) showAsterisk: boolean = false;
  @Input({ transform: booleanAttribute }) showOptionalHint: boolean = false;
  @Input({ transform: booleanAttribute }) hideErrorMessage: boolean = false;
  @Input({ transform: booleanAttribute }) hideInfoIcon: boolean = false;

  @ViewChild('colorPickerContent') private colorPickerContent?: TemplateRef<any>;
  @ViewChild('colorOverlayToggle') private colorPickerOverlayToggle?: ElementRef;
  private colorPickerRef?: OverlayRef;
  protected colorPickerCustomColorControl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.pattern(/^(?:[0-9a-fA-F]{3}){1,2}$/)],
  });

  editorRef?: QuillEditor;
  public isRequired: boolean = false;

  public quillModules!: QuillModules;
  public quillFormats: string[] = [];
  public quillContent: string = '';
  public toolbarConfig: MfHtmlInputToolbarFeaturesConfig = [];
  public fontSizes: (boolean | string)[] = [];
  public colors = inject(MfBankColorThemeService).getColorOptions();

  private valueTextLength: number = 0;

  private formService = inject(MfFormService);
  private dialog = inject(MfDialogService);
  private destroyRef = inject(DestroyRef);
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);

  private readonly placeholderDelimiters: [string, string] = ['{{', '}}'];

  constructor(
    /* fontSizes attribute only supports values defined in the `whitelistedFontSizes`-Array. On-the-fly whitelisting of sizes
     * is not possible to my knowledge, as the whitelist applies to all currently visible editors and therefore will be most
     * likely overwritten by the default config. */
    @Attribute('fontSizes') private readonly externalFontSizesOptions: string,
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string
  ) {
    super();

    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
    this.placeholder = '';

    this.parseAndSetFontSizes(this.externalFontSizesOptions);

    this.colorPickerCustomColorControl.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        if (value.includes('#')) {
          this.colorPickerCustomColorControl.setValue(value.replace('#', ''));
        }
      });
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(!this.showAsterisk, this.control);

    this.setToolbarConfig();
    this.setQuillModules();
    this.initValueUpdateListener();

    this.initQuillContent();
  }

  get inputLength(): number {
    return this.valueTextLength || 0;
  }

  get hasWarning(): boolean {
    return !!(
      this.maxLengthHint &&
      this.inputLength &&
      this.lengthWarning &&
      this.inputLength > this.lengthWarning
    );
  }

  get hasError(): boolean {
    return this.control.touched && this.control.invalid;
  }

  openPlaceholderSelect() {
    if (!this.placeholderItems) {
      return;
    }

    let selection = this.editorRef?.getSelection();

    this.dialog
      .open(MfFormHtmlInputPlaceholderSelectComponent, this.placeholderItems)
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        for (const placeholder of result) {
          switch (placeholder) {
            case MfHtmlInputPlaceholderConstant.LINE_BREAK:
              this.insertString('\n', selection);
              break;

            case MfHtmlInputPlaceholderConstant.SPACE:
              this.insertString(' ', selection);
              break;

            default:
              this.insertPlaceholder(placeholder, selection);
              break;
          }

          // Update selection variable after an insert happened
          selection = this.editorRef?.getSelection();
        }
      });
  }

  insertPlaceholder(
    placeholder: MfHtmlInputPlaceholder,
    selection = this.editorRef?.getSelection()
  ) {
    if (!this.editorRef) {
      return;
    }

    if (selection) {
      this.editorRef.deleteText(selection.index, selection.length);
    }

    const insertAt = selection?.index || 0;
    this.editorRef.insertEmbed(insertAt, 'placeholder', placeholder, Quill.sources.USER);
    this.editorRef.setSelection(insertAt + 1, 0);
  }

  insertString(text: string, selection = this.editorRef?.getSelection()) {
    if (!this.editorRef) {
      return;
    }

    if (selection) {
      this.editorRef.deleteText(selection.index, selection.length);
    }

    const insertAt = selection?.index || 0;
    this.editorRef.insertText(insertAt, text, Quill.sources.USER);
    this.editorRef.setSelection(insertAt + text.length, 0);
  }

  public hasToolbarFeature(feature: MfHtmlInputFeatures): boolean {
    return this.toolbarConfig.includes(feature);
  }

  public hasToolbarFeatures(features: MfHtmlInputFeatures[]): boolean {
    return features.some((feature) => this.hasToolbarFeature(feature));
  }

  public parseAndSetFontSizes(externalOptions?: string): number[] {
    let fontSizes: number[] = DEFAULT_FONT_SIZE_OPTIONS;
    if (externalOptions) {
      fontSizes = externalOptions
        .split(',')
        .map((value: string) => parseInt(value, 10))
        .filter((value: number) => !Number.isNaN(value));

      fontSizes.sort((a: number, b: number) => a - b);
    }

    const sizes: string[] = fontSizes.map((size: number) => `${size}px`);

    this.fontSizes = [false, ...sizes];

    return fontSizes;
  }

  private setQuillModules(): void {
    this.quillModules = {
      placeholder: {
        delimiters: this.placeholderDelimiters,
        placeholders: this.placeholderItems,
      },
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler: () => true,
          },
        },
      },
    };
  }

  private setToolbarConfig(): void {
    if (this.toolbarFeaturesType === 'custom') {
      if (!this.toolbarFeaturesConfig || !Array.isArray(this.toolbarFeaturesConfig)) {
        throw new Error(
          'MfFormHtmlInputComponent: toolbarFeaturesType "custom" is used but no toolbarFeaturesConfig is provided!'
        );
      }

      this.toolbarConfig = this.toolbarFeaturesConfig;
    } else {
      const config = HTML_INPUT_TOOLBAR_CONFIGS[this.toolbarFeaturesType];

      if (!config) {
        throw new Error(
          `MfFormHtmlInputComponent: toolbarFeaturesType +
          "${this.toolbarFeaturesType}" not recognized! Use value "custom" to pass a custom toolbar configuration.`
        );
      }

      this.toolbarConfig = config;
    }

    this.setQuillFormats();
  }

  private setQuillFormats(): void {
    const formats: any[] = [];

    if (this.hasToolbarFeature(MfHtmlInputFeatures.BOLD)) {
      formats.push('bold');
    }

    if (this.hasToolbarFeature(MfHtmlInputFeatures.ITALIC)) {
      formats.push('italic');
    }

    if (this.hasToolbarFeature(MfHtmlInputFeatures.UNDERLINE)) {
      formats.push('underline');
    }

    if (this.hasToolbarFeature(MfHtmlInputFeatures.COLOR)) {
      formats.push('color');
    }

    if (this.hasToolbarFeature(MfHtmlInputFeatures.SIZE)) {
      formats.push('size');
    }

    if (
      this.hasToolbarFeature(MfHtmlInputFeatures.LIST_BULLET) ||
      this.hasToolbarFeature(MfHtmlInputFeatures.LIST_ORDERED)
    ) {
      formats.push('list');
    }

    if (
      this.hasToolbarFeature(MfHtmlInputFeatures.TEXT_LEFT) ||
      this.hasToolbarFeature(MfHtmlInputFeatures.TEXT_CENTER) ||
      this.hasToolbarFeature(MfHtmlInputFeatures.TEXT_RIGHT) ||
      this.hasToolbarFeature(MfHtmlInputFeatures.TEXT_JUSTIFY)
    ) {
      formats.push('align');
    }

    if (this.hasToolbarFeature(MfHtmlInputFeatures.LINKS)) {
      formats.push('link');
    }

    if (this.placeholderItems) {
      formats.push('placeholder');
    }

    this.quillFormats = formats;
  }

  private initValueUpdateListener(): void {
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (value: string) => {
        this.valueTextLength = value ? StringUtil.lengthOfTextInHtml(value) : 0;
        this.initQuillContent();
      },
    });
  }

  public initQuillContent(): void {
    let content = this.control.value;
    content = this.replaceQuillPlaceholders(content, 'label');

    this.quillContent = content;
  }

  public onChangeQuillContent(quillContent: string): void {
    this.quillContent = quillContent;

    let content = this.quillContent;
    content = this.quillContent ? this.replaceQuillPlaceholders(content, 'id') : '';

    this.control.setValue(content);
    this.control.markAsDirty();
  }

  public handleBlur(): void {
    this.control.markAsTouched();
  }

  public replaceQuillPlaceholders(content: string, displayedFieldType: 'id' | 'label'): string {
    const domParser: DOMParser = new DOMParser();
    const { body }: Document = domParser.parseFromString(content, 'text/html');

    // By default, quill placeholders module places the value of a placeholder within the delimiters. But we want the id to be there.
    // Therefore, we replace the id with the value when preparing quill values und replace the value with the id when preparing the
    // value of the control.
    body.querySelectorAll('span.ql-placeholder-content').forEach((node: Element) => {
      const value: string = node.getAttribute('data-' + displayedFieldType) || '';
      const targetElement = node.querySelector('span[contenteditable]');
      if (targetElement) {
        targetElement.textContent =
          this.placeholderDelimiters[0] + value + this.placeholderDelimiters[1];
      }
    });

    return body.innerHTML;
  }

  protected readonly MfHtmlInputFeatures = MfHtmlInputFeatures;

  pickDefaultColor(color: string, closePicker: boolean = true) {
    this.colorPickerCustomColorControl.setValue('');
    this.pickColor(color, closePicker);
  }

  pickColor(color: string, closePicker: boolean = true) {
    if (!this.editorRef) {
      return;
    }

    this.editorRef.format('color', color, 'user');

    if (closePicker) {
      this.closeColorPickerOverlay();
    }
  }

  pickCustomColor(closePicker: boolean = true) {
    let customValue = this.colorPickerCustomColorControl.value.trim();
    if (!customValue || this.colorPickerCustomColorControl.invalid) {
      return;
    }

    this.pickColor('#' + customValue, closePicker);
  }

  openColorSelect() {
    this.openColorPickerOverlay();
  }

  private createOverlay(): void {
    if (!this.colorPickerOverlayToggle) {
      throw Error('Could not find overlayToggle element!');
    }

    const scrollStrategy = this.overlay.scrollStrategies.close();

    const topRightPosition = new ConnectionPositionPair(
      { originX: 'center', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'top' }
    );

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.colorPickerOverlayToggle)
      .withPositions([topRightPosition])
      .withViewportMargin(16)
      .withPush(true);

    this.colorPickerRef = this.overlay.create({
      positionStrategy,
      scrollStrategy,
      disposeOnNavigation: true,
    });

    this.colorPickerRef
      .outsidePointerEvents()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.closeColorPickerOverlay();
      });
  }

  private openColorPickerOverlay(): void {
    if (!this.colorPickerRef) {
      this.createOverlay();
    }

    if (!this.colorPickerRef!.hasAttached() && this.colorPickerContent) {
      const currentColor: string | null = this.editorRef?.getFormat()?.['color'];
      if (
        currentColor &&
        !this.colors.some(
          (item) => String(item.value).toLowerCase() === String(currentColor).toLowerCase()
        )
      ) {
        this.colorPickerCustomColorControl.setValue(currentColor.slice(1));
      }

      const portal = new TemplatePortal(this.colorPickerContent, this.viewContainerRef);

      this.colorPickerRef?.attach(portal);
    }
  }

  private closeColorPickerOverlay(): void {
    if (this.colorPickerCustomColorControl.valid) {
      this.pickCustomColor(false);
    }

    this.colorPickerCustomColorControl.reset();

    if (this.colorPickerRef?.hasAttached()) {
      this.colorPickerRef.detach();
    }
  }
}
