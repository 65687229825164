import { inject, Injectable } from '@angular/core';
import { MfUserService } from '@shared/authentication/user/user.service';
import { MfBankColorTheme } from '@shared/bank/bank-color-theme/bank-color-theme.enum';
import { MfValueLabelUtil } from '@shared/util/value-label.util';

/* eslint-disable @typescript-eslint/naming-convention */
const COLORS_VR = {
  '#FF6600': 'VR Brand Orange',
  '#002D67': 'VR Ultra Marine',
  '#0066B3': 'VR Brand Blue',
  '#2382C8': 'VR Hellblau',
  '#E5EFF7': 'VR leichtes Blau',
  '#FFFFFF': 'Weiß',
  '#F3F3F3': 'Hellgrau (Hintergrund*)',
  '#CCCCCC': 'Hellgrau (Linien*)',
  '#5A5A5A': 'Grau (Überschriften*)',
  '#333333': 'Dunkelgrau (Texte*)',
  '#000000': 'Schwarz',
} as const satisfies Record<string, string>;

const COLORS_PSD = {
  '#008C58': 'PSD Grün',
  '#e6f5f0': 'Hellgrün (Hintergrund*)',
  '#C9CF00': 'Limette',
  '#E6A200': 'Gelb (Button*)',
  '#FFFFFF': 'Weiß',
  '#F3F3F3': 'Hellgrau',
  '#646464': 'Grau (Texte*)',
  '#000000': 'Schwarz',
} as const satisfies Record<string, string>;

const COLORS_UMWELT_BANK = {
  '#EAF6F0': 'Forest 100',
  '#ADDCC5': 'Forest 200',
  '#88CAAA': 'Forest 300',
  '#339977': 'Forest 400',
  '#077D66': 'Forest 500',
  '#096655': 'Forest 600',
  '#F1F4F4': 'Stone 100',
  '#DCE7E7': 'Stone 200',
  '#A8BDBD': 'Stone 300',
  '#779292': 'Stone 400',
  '#44686C': 'Stone 500',
  '#1C4044': 'Stone 600',
  '#C9D5F6': 'Blue 100',
  '#416CD8': 'Blue 200',
  '#223563': 'Blue 300',
  '#FAF3C2': 'Yellow 100',
  '#EECF2A': 'Yellow 200',
  '#594C0D': 'Yellow 300',
  '#EFC8C8': 'Red 100',
  '#BF4040': 'Red 200',
  '#571E1E': 'Red 300',
  '#C9E9B9': 'Green 100',
  '#339955': 'Green 200',
  '#1C4B03': 'Green 300',
  '#FAF6F3': 'Sand',
  '#B25E53': 'Sunset',
  '#FBDD63': 'Capri',
  '#000000': 'Schwarz',
  '#FFFFFF': 'Weiß',
} as const satisfies Record<string, string>;

const COLOR_THEME_COLORS = {
  [MfBankColorTheme.VR]: COLORS_VR,
  [MfBankColorTheme.PSD]: COLORS_PSD,
  [MfBankColorTheme.UMWELT_BANK]: COLORS_UMWELT_BANK,
} as const satisfies Record<MfBankColorTheme, Record<string, string>>;
/* eslint-enable */

type AllColorOptions = typeof COLORS_VR & typeof COLORS_PSD & typeof COLORS_UMWELT_BANK;
type AvailableColors = keyof AllColorOptions & string;

@Injectable({
  providedIn: 'root',
})
export class MfBankColorThemeService {
  private userService = inject(MfUserService);

  getTheme() {
    return this.userService.getBank()?.colorTheme || MfBankColorTheme.VR;
  }

  getColorOptions() {
    return MfValueLabelUtil.fromEntries(Object.entries(COLOR_THEME_COLORS[this.getTheme()]));
  }

  getDefaultColorsHeadline(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#0066B3';

      case MfBankColorTheme.PSD:
        return '#008C58';

      case MfBankColorTheme.UMWELT_BANK:
        return '#077D66';
    }
  }

  getDefaultColorsButton(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
      case MfBankColorTheme.PSD:
      case MfBankColorTheme.UMWELT_BANK:
        return '#FFFFFF';
    }
  }

  getDefaultColorsButtonBackground(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#FF6600';

      case MfBankColorTheme.PSD:
        return '#E6A200';

      case MfBankColorTheme.UMWELT_BANK:
        return '#077D66';
    }
  }

  getDefaultColorsContainer(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#333333';

      case MfBankColorTheme.PSD:
        return '#333333';

      case MfBankColorTheme.UMWELT_BANK:
        return '#1C4044';
    }
  }

  getDefaultColorsContainerBackground(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
      case MfBankColorTheme.PSD:
      case MfBankColorTheme.UMWELT_BANK:
      default:
        return '#FFFFFF';
    }
  }

  getDefaultColorsAccordionBackground(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#0066B3';

      case MfBankColorTheme.PSD:
        return '#E6A200';

      case MfBankColorTheme.UMWELT_BANK:
        return '#077D66';
    }
  }

  getDefaultColorsAccordionBorder(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#002D67';

      case MfBankColorTheme.PSD:
        return '#E6A200';

      case MfBankColorTheme.UMWELT_BANK:
        return '#096655';
    }
  }

  getDefaultColorsAccordionColor(): AvailableColors {
    switch (this.getTheme()) {
      case MfBankColorTheme.VR:
        return '#FFFFFF';

      case MfBankColorTheme.PSD:
        return '#FFFFFF';

      case MfBankColorTheme.UMWELT_BANK:
        return '#FFFFFF';
    }
  }
}
