import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MfDataManagerImportPasswordsInterface } from '@app/data-manager/components/import/uploader/uploader.types';
import {
  MfDataManagerImportInterface,
  MfDataManagerImportRecentInterface,
  MfDataManagerImportStatusType,
} from '@app/data-manager/services/import-data/data.interface';
import { MfMime } from '@shared/types/mime-types.enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfDataManagerImportDataService {
  private uploadItems$ = new BehaviorSubject<MfDataManagerImportRecentInterface[]>([]);
  public uploadItems = this.uploadItems$.asObservable();

  constructor(private http: HttpClient) {}

  updateUploadItemList(uploadItems: MfDataManagerImportRecentInterface[]): void {
    this.uploadItems$.next(uploadItems);
  }

  /** Requests new uploadItemsList and updates uploadItems$-subject when request completes.
   * Will return false if request fails and true if request succeeds
   */
  public updateUploadItems(): Observable<boolean> {
    return this.getUploadsRecentList().pipe(
      map((uploadItems: MfDataManagerImportRecentInterface[]) => {
        this.updateUploadItemList(uploadItems);

        return true;
      }),
      catchError(() => {
        this.updateUploadItemList([]);

        return of<boolean>(false);
      })
    );
  }

  // istanbul ignore next
  public uploadFile(data: FormData): Observable<HttpEvent<MfDataManagerImportInterface>> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set('Accept', 'multipart/form-data');
    headers.set('Content-Type', 'multipart/form-data');

    return this.http.post<MfDataManagerImportInterface>('v1/data/uploads', data, {
      observe: 'events',
      reportProgress: true,
      headers,
    });
  }

  // istanbul ignore next
  public patchPasswords(
    id: number,
    passwords: MfDataManagerImportPasswordsInterface
  ): Observable<MfDataManagerImportInterface> {
    return this.http.patch<MfDataManagerImportInterface>(`v1/data/uploads/${id}`, passwords);
  }

  // istanbul ignore next
  public getUploadsList(): Observable<MfDataManagerImportInterface[]> {
    return this.http.get<MfDataManagerImportInterface[]>('v1/data/uploads');
  }

  // istanbul ignore next
  public getUploadsRecentList(): Observable<MfDataManagerImportRecentInterface[]> {
    return this.http.get<MfDataManagerImportRecentInterface[]>('v1/data/uploads/recent');
  }

  // istanbul ignore next
  public getImportProtocol(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`v1/data/uploads/${id}/log`, {
      observe: 'response',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': MfMime.CSV,
      },
      responseType: 'blob',
    });
  }

  // istanbul ignore next
  public getImportDataStatus(id: number): Observable<MfDataManagerImportStatusType> {
    return this.http.get<MfDataManagerImportStatusType>(`v1/data/imports/${id}/status`);
  }

  // istanbul ignore next
  public getLatestImport(): Observable<Date> {
    return this.http
      .get<MfDataManagerImportInterface>('v1/data/latestimport')
      .pipe(map((latestImport) => this.getLatestUpdate(latestImport)));
  }

  getLatestUpdate(latestImport: MfDataManagerImportInterface): Date {
    const importStates = latestImport._embedded.state;
    const latestImportState = importStates[importStates.length - 1];

    return new Date(latestImportState.key);
  }
}
