import { APP_INITIALIZER, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { MfBankCustomizationValueType } from '@app/shared/bank-customization/bank-customization.type';
import { environment } from '@env/environment';
import { versionInformation } from '@env/version';
import * as Sentry from '@sentry/angular-ivy';
import { User } from '@sentry/angular-ivy';
import { MfDataUserInterface } from '@shared/data/data.interface';

export class SentryConfig {
  private static readonly FEATURES_CONTEXT_KEY = 'bankFeatures';

  static get sentryIsEnabled(): boolean {
    return !!environment.sentry.dsn;
  }

  static initSentry(): void {
    if (this.sentryIsEnabled) {
      Sentry.init(this.baseConfig);

      Sentry.setTag('baseUrl', environment.baseUrl);
      Sentry.setTag('serverUrl', environment.serverUrl);
      Sentry.setTag('version', versionInformation.version);
    }
  }

  static getProviders(): Provider[] {
    if (!this.sentryIsEnabled) {
      return [];
    }

    return [
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ];
  }

  static setUser(user?: MfDataUserInterface): void {
    if (user) {
      const sentryUser: User = { id: String(user.id), roles: user.roles };

      if (user?._embedded?.bank) {
        sentryUser['bankId'] = user._embedded.bank.id;
      }

      Sentry.setUser(sentryUser);
    }
  }

  static setFeaturesContext(features: MfBankCustomizationValueType): void {
    if (typeof features === 'string') {
      return;
    }

    Sentry.setContext(this.FEATURES_CONTEXT_KEY, features || null);
  }

  static clearUserRelatedData(): void {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });

    Sentry.setContext(this.FEATURES_CONTEXT_KEY, null);
  }

  static captureMessage(...args: Parameters<(typeof Sentry)['captureMessage']>): void {
    Sentry.captureMessage(...args);
  }

  private static get baseConfig(): Sentry.BrowserOptions {
    return {
      dsn: environment.sentry.dsn,
      environment: environment.type,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [/^https:\/\/.+\.marketingfabrik\.app/],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
        new Sentry.Replay(),
      ],
      release: `frontend@${versionInformation.version}`,
      sampleRate: environment.sentry.sampleRate,
      tracesSampleRate: environment.sentry.tracesSampleRate,
      replaysSessionSampleRate: environment.sentry.replaysSessionSampleRate,
      replaysOnErrorSampleRate: environment.sentry.replaysOnErrorSampleRate,
      beforeSend: (event): null | Sentry.Event => {
        // Filter http-errors
        if ((event.extra?.['__serialized__'] as any)?.name === 'HttpErrorResponse') {
          return null;
        }

        return event;
      },
    };
  }
}
