@if (control) {
  <div class="pb-4" [ngClass]="[name]" #componentWrapper>
    @if (label) {
      <mat-label class="mat-label mat-caption mb-2 ml-2">{{ label }}</mat-label>
    }

    <div class="mb-1 flex flex-col">
      <quill-editor
        class="flex-1"
        [attr.name]="name"
        [bounds]="componentWrapper"
        [class.error]="hasError"
        [class.warning]="hasWarning"
        [formats]="quillFormats"
        [modules]="quillModules"
        [ngModel]="quillContent"
        [placeholder]="placeholder"
        [required]="isRequired"
        (ngModelChange)="onChangeQuillContent($event)"
        (onBlur)="handleBlur()"
        (onEditorCreated)="editorRef = $event"
        format="html"
        linkPlaceholder="https://marketingfabrik.app"
      >
        <div quill-editor-toolbar>
          @if (
            hasToolbarFeatures([
              MfHtmlInputFeatures.BOLD,
              MfHtmlInputFeatures.ITALIC,
              MfHtmlInputFeatures.UNDERLINE,
            ])
          ) {
            <span class="ql-formats">
              @if (hasToolbarFeature(MfHtmlInputFeatures.BOLD)) {
                <button class="ql-bold" matTooltip="Fett"></button>
              }

              @if (hasToolbarFeature(MfHtmlInputFeatures.ITALIC)) {
                <button class="ql-italic" matTooltip="Kursiv"></button>
              }

              @if (hasToolbarFeatures([MfHtmlInputFeatures.UNDERLINE])) {
                <button class="ql-underline" matTooltip="Unterstrichen"></button>
              }
            </span>
          }

          @if (hasToolbarFeatures([MfHtmlInputFeatures.COLOR])) {
            <span class="ql-formats">
              <button
                class="mf-ql-placeholder"
                (click)="openColorSelect()"
                #colorOverlayToggle
                type="button"
              >
                <mat-icon aria-hidden="true" svgIcon="format_color_text" />
                <span class="sr-only">Farbe</span>
              </button>

              <ng-template #colorPickerContent>
                <div class="rounded-lg border border-monochrome-100 bg-white p-2 shadow-lg">
                  <div class="grid grid-cols-5 gap-1.5">
                    @for (color of colors; track color.value) {
                      <button
                        class="size-6 rounded-sm outline-primary-500 hover:outline"
                        [style.background-color]="color.value"
                        [title]="color.label"
                        (click)="pickDefaultColor($any(color.value))"
                        type="button"
                      ></button>
                    }
                  </div>

                  <form class="mt-2 flex gap-1" (ngSubmit)="pickCustomColor()">
                    <div class="relative w-24">
                      <span class="absolute left-1 top-0.5 block font-bold">#</span>
                      <input
                        class="block w-full border px-1 pl-4"
                        [formControl]="colorPickerCustomColorControl"
                        [ngClass]="
                          colorPickerCustomColorControl.touched &&
                          colorPickerCustomColorControl.errors
                            ? 'border-error-500 bg-error-100'
                            : 'border-monochrome-300'
                        "
                        placeholder="Farbcode"
                      />
                    </div>
                    <button
                      class="flex size-7 items-center justify-center bg-primary-500"
                      type="submit"
                    >
                      <mat-icon class="size-4" svgIcon="check" />
                    </button>
                  </form>
                </div>
              </ng-template>
            </span>
          }

          @if (
            hasToolbarFeatures([
              MfHtmlInputFeatures.TEXT_LEFT,
              MfHtmlInputFeatures.TEXT_RIGHT,
              MfHtmlInputFeatures.TEXT_CENTER,
              MfHtmlInputFeatures.TEXT_JUSTIFY,
            ])
          ) {
            <span class="ql-formats">
              @if (hasToolbarFeatures([MfHtmlInputFeatures.TEXT_LEFT])) {
                <button class="ql-align" matTooltip="Linksbündig" value=""></button>
              }

              @if (hasToolbarFeatures([MfHtmlInputFeatures.TEXT_CENTER])) {
                <button class="ql-align" matTooltip="Zentriert" value="center"></button>
              }

              @if (hasToolbarFeatures([MfHtmlInputFeatures.TEXT_RIGHT])) {
                <button class="ql-align" matTooltip="Rechtsbündig" value="right"></button>
              }

              @if (hasToolbarFeatures([MfHtmlInputFeatures.TEXT_JUSTIFY])) {
                <button class="ql-align" matTooltip="Blocksatz" value="justify"></button>
              }
            </span>
          }
          @if (
            hasToolbarFeatures([MfHtmlInputFeatures.LIST_ORDERED, MfHtmlInputFeatures.LIST_BULLET])
          ) {
            <span class="ql-formats">
              @if (hasToolbarFeatures([MfHtmlInputFeatures.LIST_ORDERED])) {
                <button class="ql-list" matTooltip="Nummerierte Liste" value="ordered"></button>
              }

              @if (hasToolbarFeatures([MfHtmlInputFeatures.LIST_BULLET])) {
                <button class="ql-list" matTooltip="Aufzählungsliste" value="bullet"></button>
              }
            </span>
          }

          @if (hasToolbarFeatures([MfHtmlInputFeatures.SIZE])) {
            <span class="ql-formats">
              <select class="ql-size">
                @for (size of fontSizes; track $index) {
                  @if (size) {
                    <option [value]="size"></option>
                  } @else {
                    <option></option>
                  }
                }
              </select>
            </span>
          }

          @if (hasToolbarFeatures([MfHtmlInputFeatures.LINKS])) {
            <span class="ql-formats">
              <button class="ql-link" matTooltip="Link"></button>
            </span>
          }

          @if (placeholderItems?.length) {
            <span class="ql-formats">
              <button
                class="mf-ql-placeholder"
                (click)="openPlaceholderSelect()"
                matTooltip="Variable einfügen"
                type="button"
              >
                <mat-icon aria-hidden="true" svgIcon="add" />
                <span>Variable</span>
              </button>
            </span>
          }

          @if (hasToolbarFeatures([MfHtmlInputFeatures.CLEAN])) {
            <span class="ql-formats">
              <button class="ql-clean" matTooltip="Formatierung löschen"></button>
            </span>
          }
        </div>
      </quill-editor>
    </div>

    <div class="hint-wrapper">
      @if (hasError) {
        <mat-error [hidden]="hideErrorMessage" [@fade-toggle]="hasError">
          <mf-form-util-error-handler
            [field]="control"
            [maxLengthHint]="maxLengthHint!"
            [patternName]="patternName"
            [translationPrefix]="translationPrefix"
            [translationPrefixScope]="translationPrefixScope"
          />
        </mat-error>
      } @else {
        <mat-hint>
          <mf-form-util-hint-handler
            [field]="control"
            [hasWarning]="hasWarning"
            [hideInfoIcon]="hideInfoIcon"
            [hintLabel]="hintLabel"
            [isRequired]="isRequired"
            [length]="inputLength"
            [maxLengthHint]="maxLengthHint!"
            [patternName]="patternName"
            [showOptionalHint]="showOptionalHint"
            [warningLabel]="warningLabel"
          />
        </mat-hint>
      }
    </div>
  </div>
}
