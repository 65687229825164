import { ArrayUtil } from '@shared/util/array.util';

export const toQueryParamsString = <V = string | null | undefined>(
  parameters: Record<string, V>,
  startWithQuestionMark: boolean = true
): string => {
  const paramsString = Object.entries(parameters)
    .filter(([_key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return key + '=' + value.toISOString();
      }

      if (Array.isArray(value)) {
        if (!value.length) {
          return undefined;
        }

        return value.map((item) => toQueryParamsString({ [key + '[]']: item }, false)).join('&');
      }

      return key + '=' + encodeURIComponent(value + '');
    })
    .filter(ArrayUtil.truthyFilter)
    .join('&');

  return paramsString ? (startWithQuestionMark ? '?' : '') + paramsString : '';
};
