import { Injectable } from '@angular/core';
import { TranslocoConfig, TranslocoMissingHandler } from '@jsverse/transloco';
import { SentryConfig } from '@shared/sentry/sentry-config';

@Injectable()
export class MfTranslocoMissingHandler implements TranslocoMissingHandler {
  // istanbul ignore next
  handle(key: string, config: TranslocoConfig) {
    const msg = `Missing translation for '${key}'`;
    if (config.missingHandler.logMissingKey && !config.prodMode) {
      console.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    } else {
      SentryConfig.captureMessage(msg);
    }

    return key;
  }
}
