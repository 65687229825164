import { MfValueLabelModel } from '@app/shared/value-label/value-label.model';
import { TranslocoService } from '@jsverse/transloco';
import { StringUtil } from '@shared/util/string.util';
import { map, take } from 'rxjs/operators';

export class MfValueLabelUtil {
  /* Creates a list of MfValueLabelModels from a Record that has labels as keys and values as values */
  static fromRecord(record: Record<string, string>): MfValueLabelModel[] {
    return Object.entries(record).map(([label, value]) => new MfValueLabelModel(value, label));
  }

  static fromEntries(entries: [MfValueLabelModel['value'], string][]): MfValueLabelModel[] {
    return entries.map(([value, label]) => new MfValueLabelModel(value, label));
  }

  static getLabel<T>(items: MfValueLabelModel<T>[], value: T, defaultLabel: string = '-') {
    return items.find((item) => item.value === value)?.label || defaultLabel;
  }

  static getTranslatedEnumEntries<T extends Record<string, string>>(
    translocoService: TranslocoService,
    lang: string,
    prefix: string,
    object: T
  ) {
    const items = Object.values(object) as T[keyof T][];

    return this.getTranslatedItems(translocoService, lang, prefix, items);
  }

  static getTranslatedItems<T extends string>(
    translocoService: TranslocoService,
    lang: string,
    prefix: string,
    items: T[]
  ) {
    return translocoService
      .selectTranslate(
        items.map((item) => prefix + StringUtil.toSnakeCase(item, true)),
        {},
        lang
      )
      .pipe(
        take(1),
        map((translations: string[]) =>
          translations.map((translation, idx) => new MfValueLabelModel<T>(items[idx], translation))
        )
      );
  }
}
