import { Injectable } from '@angular/core';
import { Freshdesk } from '@app/shared/freshdesk/freshdesk.service';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { MfDataUserInterface } from '@shared/data/data.interface';

@Injectable({
  providedIn: 'root',
})
export class MfUserStorage {
  private readonly LOCAL_STORAGE_KEY = 'user';

  public getUser(): MfDataUserInterface | undefined {
    const userString = localStorage.getItem(this.LOCAL_STORAGE_KEY);

    if (userString) {
      let user: MfDataUserInterface | undefined;

      try {
        user = JSON.parse(userString) as MfDataUserInterface;
      } catch {}

      return user;
    }

    return undefined;
  }

  public setUser(user: MfDataUserInterface): MfUserStorage {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(user));

    SentryConfig.setUser(user);
    Freshdesk.setUser(user);

    return this;
  }

  public clearUser(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }
}
