import { MfDataUserInterface } from '@shared/data/data.interface';

export enum MfUserEventType {
  LOGIN = 'login',
  UPDATED = 'update',
  LOGOUT = 'logout',
}

export type MfUserEvent = MfUserEventMap[MfUserEventType];

export interface MfUserEventMap {
  [MfUserEventType.LOGIN]: { type: MfUserEventType.LOGIN; data: MfDataUserInterface };
  [MfUserEventType.UPDATED]: { type: MfUserEventType.UPDATED; data: MfDataUserInterface };
  [MfUserEventType.LOGOUT]: { type: MfUserEventType.LOGOUT };
}
