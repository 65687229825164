import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { MfConfirmDialogComponent } from '@app/shared/dialog/confirm-dialog/confirm-dialog.component';
import { MfConfirmDialogInterface } from '@app/shared/dialog/confirm-dialog/confirm-dialog.interface';
import { ConfirmDialogDoNotShowAgainKeys } from '@app/shared/dialog/do-not-show-again.enum';
import { MfAbstractDialog } from '@app/shared/dialog/service/dialog.interface';
import { MfVimeoVideoDialogComponent } from '@app/shared/dialog/vimeo-video-dialog/vimeo-video-dialog.component';
import { MfUserCustomizationService } from '@app/shared/user-customization/user-customization.service';
import { TranslocoService } from '@jsverse/transloco';
import { MfAlertDialogComponent } from '@shared/dialog/alert-dialog/alert-dialog.component';
import { MfInlineEditComboboxDialogComponent } from '@shared/dialog/inline-edit-combobox-dialog/inline-edit-combobox-dialog.component';
import { MfInlineEditComboboxDialogData } from '@shared/dialog/inline-edit-combobox-dialog/inline-edit-combobox-dialog.interface';
import { MfInlineEditSignatureDialogComponent } from '@shared/dialog/inline-edit-signature-dialog/inline-edit-signature-dialog.component';
import {
  MfInlineEditSignatureDialogData,
  MfInlineEditSignatureDialogResult,
} from '@shared/dialog/inline-edit-signature-dialog/inline-edit-signature-dialog.interface';
import { MfValueLabelModel } from '@shared/value-label/value-label.model';
import { MfValueLabelValueType } from '@shared/value-label/value-label.type';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

export type DialogInferDataType<T> = T extends MfAbstractDialog<infer X, any> ? X : never;
type DialogInferReturnType<T> = T extends MfAbstractDialog<any, infer X> ? X : never;

interface ConfirmDialogInterface {
  translationGroup: string;
  translationParams?: Record<string, unknown>;
  scope?: string;
  doNotShowAgainKey?: ConfirmDialogDoNotShowAgainKeys;
  noInitialFocus?: boolean;
  usePrimaryButton?: boolean;
  disableClose?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class MfDialogService {
  constructor(
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private userCustomizationService: MfUserCustomizationService
  ) {}

  openConfirm({
    translationGroup,
    translationParams = {},
    scope = undefined,
    doNotShowAgainKey,
    noInitialFocus,
    usePrimaryButton = false,
    disableClose = false,
  }: ConfirmDialogInterface): Observable<boolean> {
    const params = {
      TITLE: translationParams,
      MESSAGE: translationParams,
      CONFIRM: translationParams,
      CLOSE: translationParams,
    };

    return forkJoin({
      customTranslation: this.translocoService
        .selectTranslateObject(translationGroup, params, scope)
        .pipe(take(1)),
      defaultTranslation: this.translocoService
        .selectTranslateObject('SHARED.DIALOG.CONFIRM', params)
        .pipe(take(1)),
    }).pipe(
      switchMap(
        ({ customTranslation, defaultTranslation }) =>
          this.openCustomConfirm({
            title: customTranslation.TITLE || defaultTranslation.TITLE,
            message: customTranslation.MESSAGE || defaultTranslation.MESSAGE,
            action: {
              primary: {
                label: customTranslation.CONFIRM || defaultTranslation.CONFIRM,
                value: 'yes',
              },
              secondary: {
                label: customTranslation.CLOSE || defaultTranslation.CLOSE,
                value: 'no',
              },
            },
            doNotShowAgainKey,
            noInitialFocus,
            options: {
              usePrimaryButton,
              disableClose,
            },
          })
            ?.afterClosed()
            .pipe(map((value) => value === 'yes')) || of(false)
      )
    );
  }

  /**
   * NOTE: Will only return undefined when config.doNotShowAgainKey is set
   */
  openCustomConfirm(data: MfConfirmDialogInterface) {
    if (
      data.doNotShowAgainKey &&
      this.userCustomizationService.shouldNotShowAgain(data.doNotShowAgainKey)
    ) {
      return undefined;
    }

    return this.open(MfConfirmDialogComponent, data, {
      disableClose: !!data.options?.disableClose,
      ...(data.noInitialFocus ? { autoFocus: undefined } : {}),
    });
  }

  openAlert(data: { title: string; message: string }) {
    return this.open(MfAlertDialogComponent, data);
  }

  openSidebar<C extends MfAbstractDialog<any, any>>(
    component: ComponentType<C>,
    data: DialogInferDataType<C>,
    config: MatDialogConfig<DialogInferDataType<C>> = {}
  ) {
    const dialogConfig: MatDialogConfig<DialogInferDataType<C>> = {
      panelClass: 'mf-sidebar',
      maxWidth: '100%',
      width: '800px',
      height: '100%',
      position: {
        right: '0',
        top: '0',
      },
      ...config,
      data,
    };

    return this.dialog.open<C, DialogInferDataType<C>, DialogInferReturnType<C>>(
      component,
      dialogConfig
    );
  }

  open<C extends MfAbstractDialog<any, any>>(
    component: ComponentType<C>,
    data: DialogInferDataType<C>,
    dialogOptions?: Exclude<MatDialogConfig, 'data' | 'width'>
  ) {
    const dialogConfig: MatDialogConfig<DialogInferDataType<C>> = {
      panelClass: 'mf-dialog',
      maxWidth: '560px',
      width: '100%',
      ...dialogOptions,
      data,
    };

    return this.dialog.open<C, DialogInferDataType<C>, DialogInferReturnType<C>>(
      component,
      dialogConfig
    );
  }

  openFullscreen<C extends MfAbstractDialog<any, any>>(
    component: ComponentType<C>,
    data: DialogInferDataType<C>,
    dialogOptions?: Exclude<MatDialogConfig, 'data' | 'width' | 'panelClass'>
  ) {
    const dialogConfig: MatDialogConfig<DialogInferDataType<C>> = {
      panelClass: ['mf-dialog', 'mf-fullscreen-modal'],
      width: '100%',
      height: '100%',
      maxWidth: '100%',
      ...dialogOptions,
      data,
    };

    return this.dialog.open<C, DialogInferDataType<C>, DialogInferReturnType<C>>(
      component,
      dialogConfig
    );
  }

  openVimeoVideo(url: string): Observable<boolean> {
    const dialogConfig: MatDialogConfig = {
      panelClass: 'mf-dialog',
      width: '800px',
      data: {
        url,
      },
    };

    return this.dialog.open(MfVimeoVideoDialogComponent, dialogConfig).afterClosed();
  }

  openInlineEditCombobox<ValueType extends MfValueLabelValueType, MetaType>(
    data: MfInlineEditComboboxDialogData
  ): Observable<MfValueLabelModel<ValueType, MetaType> | undefined> {
    return this.open(MfInlineEditComboboxDialogComponent<ValueType, MetaType>, data, {
      width: '800px',
    }).afterClosed();
  }

  openInlineEditSignature(
    data: MfInlineEditSignatureDialogData
  ): Observable<MfInlineEditSignatureDialogResult | undefined> {
    return this.open(MfInlineEditSignatureDialogComponent, data, {
      width: '800px',
    }).afterClosed();
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
