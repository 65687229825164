import { MfOrganizationConsultant } from '@app/organization/consultant/data/data.interface';
import { ADMIN_USER_ROLES } from '@app/shared/upsert-user/upsert-user.const';
import { MfValueLabelModel } from '@app/shared/value-label/value-label.model';
import { MfDataUserInterface } from '@shared/data/data.interface';

export class MfUserUtil {
  static toValueLabel(user: { id: number; firstname?: string; lastname?: string }) {
    return new MfValueLabelModel(user.id, this.formatFullName(user));
  }

  static formatFullNameConsultant(
    consultant: Partial<Pick<MfOrganizationConsultant, 'firstName' | 'lastName'>>
  ): string {
    return (
      this.formatFullName({ firstname: consultant.firstName, lastname: consultant.lastName }) || '-'
    );
  }

  static formatFullName(user: { firstname?: string; lastname?: string }): string {
    if (!user) {
      return '';
    }

    const segments = [];

    if (user.firstname) {
      segments.push(user.firstname);
    }

    if (user.lastname) {
      segments.push(user.lastname);
    }

    return segments.join(' ');
  }

  static formatCompleteName(user: {
    salutation?: string;
    firstname?: string;
    lastname?: string;
  }): string {
    if (!user) {
      return '';
    }

    const segments = [];

    if (user.salutation) {
      segments.push(user.salutation);
    }

    if (user.firstname) {
      segments.push(user.firstname);
    }

    if (user.lastname) {
      segments.push(user.lastname);
    }

    return segments.join(' ');
  }

  static isAdminUser(user: Pick<MfDataUserInterface, 'roles' | '_embedded'>): boolean {
    return !!(
      !user._embedded?.bank &&
      user.roles?.some((userRole) =>
        ADMIN_USER_ROLES.map((role) => String(role.value)).includes(userRole)
      )
    );
  }
}
