import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MfFormSelectComponent } from '@app/form/field/select/select.component';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import { MfInlineEditComboboxDialogData } from '@shared/dialog/inline-edit-combobox-dialog/inline-edit-combobox-dialog.interface';
import { MfAbstractDialogInjected } from '@shared/dialog/service/dialog.interface';
import { MfValueLabelModel } from '@shared/value-label/value-label.model';
import { MfValueLabelValueType } from '@shared/value-label/value-label.type';
import { finalize, Observable, of } from 'rxjs';

@Component({
  selector: 'mf-inline-edit-combobox-dialog',
  templateUrl: './inline-edit-combobox-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MaterialModule,
    MfTertiaryButtonComponent,
    MfPrimaryButtonComponent,
    MfFormSelectComponent,
    MfFormFocusFirstInvalidFieldDirective,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfInlineEditComboboxDialogComponent<K extends MfValueLabelValueType, Meta>
  extends MfAbstractDialogInjected<MfInlineEditComboboxDialogData, MfValueLabelModel<K, Meta>>
  implements OnInit
{
  public readonly form = this.createForm();
  public items: MfValueLabelModel<K, Meta>[] = [];
  public loading: boolean = false;

  private cdr = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.loadItems();
  }

  private loadItems(): void {
    this.loading = true;

    let items$: Observable<MfValueLabelModel[]>;
    if (this.data.items instanceof Observable) {
      items$ = this.data.items;
    } else {
      items$ = of(this.data.items);
    }

    items$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => (this.loading = false))
      )
      .subscribe((items) => {
        this.items = items as MfValueLabelModel<K, Meta>[];
        this.cdr.detectChanges();
      });
  }

  createForm() {
    return new FormGroup({
      item: new FormControl(this.data.value, {
        nonNullable: true,
        validators: [...(this.data.optional ? [] : [Validators.required])],
      }),
    });
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.form.value.item) {
      this.dialogRef.close(null);
    }

    this.close(this.items.find((item) => item.value === this.form.value.item));
  }
}
