import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Event, NavigationEnd, Router, RouterLink } from '@angular/router';
import { MfLayoutSidenavContentComponent } from '@app/layout/sidenav/content/content.component';
import { MfLayoutSidenavUserActionsComponent } from '@app/layout/sidenav/user-actions/user-actions.component';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';
import { MfUserEventType } from '@app/shared/authentication/user/user-event.model';
import { MfUserEventService } from '@app/shared/authentication/user/user-event.service';
import { MfUserService } from '@app/shared/authentication/user/user.service';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { MfUserUtil } from '@app/shared/util/user.util';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { MfDataUserInterface } from '@shared/data/data.interface';
import { Maybe } from '@shared/types/util.types';
import { Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'mf-layout-sidenav',
  templateUrl: './sidenav.component.html',
  styles: [
    `
      :host {
        @apply flex h-full flex-col divide-y divide-monochrome-500;
      }
    `,
  ],
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    MfLayoutSidenavContentComponent,
    MfLayoutSidenavUserActionsComponent,
  ],
})
export class MfLayoutSidenavComponent extends MfUnsubscribeComponent implements OnInit {
  public entityName: string = '';
  public userName: string = '';

  constructor(
    private userService: MfUserService,
    private activatedRoute: ActivatedRoute,
    private userEventService: MfUserEventService,
    private translocoService: TranslocoService,
    private router: Router,
    private titleService: Title,
    private navigationService: MfNavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setPageTitle();
    this.initUserDataUpdater();
  }

  private initUserDataUpdater(): void {
    this.userEventService
      .onEvent()
      .pipe(
        this.takeUntilUnsubscribe(),
        map((event) => {
          switch (event.type) {
            case MfUserEventType.LOGIN:
            case MfUserEventType.UPDATED:
              return event.data;

            case MfUserEventType.LOGOUT:
              return null;
          }
        }),
        startWith(this.userService.getUser())
      )
      .subscribe({
        next: (user) => {
          this.setUserInfo(user);
        },
      });
  }

  private setUserInfo(user: Maybe<MfDataUserInterface>): void {
    if (!user) {
      this.userName = '';
      this.entityName = '';

      return;
    }

    this.userName = MfUserUtil.formatFullName(user);

    if (user._embedded?.bank) {
      this.entityName = user._embedded.bank.name;
    } else {
      this.translocoService
        .selectTranslate('SHARED.INDIWE')
        .pipe(take(1))
        .subscribe((translation: string) => {
          this.entityName = translation;
        });
    }
  }

  public redirectUrl(): string[] {
    return this.userService.isLoggedIn()
      ? [`/${this.navigationService.suggestDefaultRouteCurrentArea()}`]
      : ['/'];
  }

  get logoTitle(): string {
    return this.navigationService.logo.imageAlt;
  }

  get logo(): string {
    return this.navigationService.logo.imageUrl;
  }

  private setPageTitle(): void {
    this.getPageTitleFromRouter().subscribe((activatedRoute: ActivatedRoute) => {
      const data: Data = activatedRoute.snapshot.data;
      let pageTitle: string;

      if (typeof data['pageTitle'] === 'function') {
        pageTitle = data['pageTitle'](activatedRoute.snapshot);
      } else {
        pageTitle = data['pageTitle'];
      }

      pageTitle = pageTitle || 'SHARED.MARKETING_FACTORY.TITLE';

      this.translocoService.selectTranslate(pageTitle).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
    });
  }

  private getPageTitleFromRouter(): Observable<ActivatedRoute> {
    return this.router.events.pipe(
      this.takeUntilUnsubscribe(),
      filter((event: Event) => event instanceof NavigationEnd),
      startWith(null),
      map(() => this.activatedRoute),
      map((route: ActivatedRoute) => {
        let currentRoute = route;

        while (currentRoute.firstChild) {
          currentRoute = currentRoute.firstChild;
        }

        return currentRoute;
      })
    );
  }
}
