import { MfMime } from '@shared/types/mime-types.enum';

interface GetImageDimensionsResult {
  width: number;
  height: number;
}

type ResolveType = (value: GetImageDimensionsResult) => void;

export const getImageDimensions: (file?: File) => Promise<GetImageDimensionsResult> = (
  file?: File
): Promise<GetImageDimensionsResult> =>
  new Promise((resolve: ResolveType, reject: VoidFunction) => {
    // no value or no file
    if (!file || !(file instanceof File)) {
      console.warn("imageDimensionValidator received a value that is empty or isn't a file", file);
      reject();

      return;
    }

    if (file.type === MfMime.PDF) {
      resolve({ width: 210, height: 297 });

      return;
    }

    // file is not an image
    if (!file.type.startsWith('image')) {
      console.warn("Mime type doesn't start with image!", file);
      reject();

      return;
    }

    const fileReader: FileReader = new FileReader();

    fileReader.onload = () => {
      if (!fileReader.result) {
        console.warn('Image error: ', file, 'Filereader returned an empty result');
        reject();

        return;
      }

      const image: HTMLImageElement = new Image();

      image.onload = () => {
        resolve({ width: image.width, height: image.height });

        return;
      };

      image.onerror = (error: any) => {
        console.warn('Image error: ', file, error);
        reject();

        return;
      };

      image.src = fileReader.result as string;
    };

    fileReader.onerror = (error: any) => {
      console.warn('Filereader error: ', file, error);
      reject();

      return;
    };

    fileReader.readAsDataURL(file);
  });
