import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { MfValueLabelModel } from '@app/shared/value-label/value-label.model';

export const SALUTATIONS: MfValueLabelModel[] = [
  { value: 'Hallo', label: 'Hallo' },
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
];

export const SALUTATIONS_EXTENDED: MfValueLabelModel[] = [
  ...SALUTATIONS,
  { value: 'Team', label: 'Team' },
];

export const TITLES: MfValueLabelModel[] = [
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Prof.', label: 'Prof.' },
  { value: 'Dr. Dr.', label: 'Dr. Dr.' },
  { value: 'Prof. Dr.', label: 'Prof. Dr.' },
];

export const ADMIN_USER_ROLES: MfValueLabelModel[] = [
  { value: MfUserRolesEnum.ADMIN_BANK_MANAGEMENT, label: 'Administration Banken' },
  { value: MfUserRolesEnum.ADMIN_SUPER_USER_MANAGEMENT, label: 'Verwaltung Admin Rollen' },
  { value: MfUserRolesEnum.ADMIN_USER_MANAGEMENT, label: 'Administration User' },
  { value: MfUserRolesEnum.ADMIN_EMAIL_TEMPLATES, label: 'Administration E-Mail Layouts' },
  { value: MfUserRolesEnum.ADMIN_DOCUMENT_MANAGEMENT, label: 'Dokumentenverwaltung' },
  { value: MfUserRolesEnum.ADMIN_MAILJET, label: 'Mailjetverwaltung' },
  { value: MfUserRolesEnum.ADMIN_DEPARTMENT_X, label: 'Verwaltung Department X' },
  { value: MfUserRolesEnum.ADMIN_MEDIA_LIBRARY, label: 'Administration Medienbibliothek' },
  { value: MfUserRolesEnum.ADMIN_IDA, label: 'Administration IDA' },
  { value: MfUserRolesEnum.ADMIN_VIDEO_MANAGEMENT, label: 'Videoverwaltung' },
  { value: MfUserRolesEnum.ADMIN_CONTRACT_MANAGEMENT, label: 'Vertragsverwaltung global' },
  { value: MfUserRolesEnum.ADMIN_BACK_OFFICE, label: 'Administration Marktfolge' },
];

export const USER_ROLES: MfValueLabelModel[] = [
  { value: MfUserRolesEnum.BANK_ADMIN, label: 'Bankadministration' },
  { value: MfUserRolesEnum.DATA_MANAGER, label: 'Datentransfer' },
  { value: MfUserRolesEnum.CAMPAIGN_MANAGER, label: 'Marketing' },
  { value: MfUserRolesEnum.REVISION, label: 'Revision' },
  { value: MfUserRolesEnum.CONTRACT_MANAGER, label: 'Vertragsmanagement' },
  { value: MfUserRolesEnum.BACK_OFFICE, label: 'Marktfolge' },
];

export const PARTNER_USER_ROLES: MfValueLabelModel[] = [
  { value: MfUserRolesEnum.PARTNER_ADMIN, label: 'Administrator Verbundpartner' },
  { value: MfUserRolesEnum.PARTNER_MARKETING_MANAGER, label: 'Marketing Verbundpartner' },
  { value: MfUserRolesEnum.PARTNER_DATA_MANAGER, label: 'Datentransfer Verbundpartner' },
  { value: MfUserRolesEnum.PARTNER_BANK_EMPLOYEE, label: 'Bankmitarbeiter' },
];

export const MARKETPLACE_BUYER_ROLE: MfValueLabelModel = {
  value: MfUserRolesEnum.MARKETPLACE_BUYER,
  label: 'Marktplatz Käufer',
};
export const MARKETPLACE_SELLER_ROLE: MfValueLabelModel = {
  value: MfUserRolesEnum.MARKETPLACE_SELLER,
  label: 'Marktplatz Verkäufer',
};

export const MARKETPLACE_ROLES = [MARKETPLACE_BUYER_ROLE, MARKETPLACE_SELLER_ROLE];
export const MARKETINGFABRIK_ROLES: MfUserRolesEnum[] = [
  ...USER_ROLES.map((role) => role.value as MfUserRolesEnum),
  ...PARTNER_USER_ROLES.map((role) => role.value as MfUserRolesEnum),
];
