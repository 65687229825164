import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { MfUserEventType } from '@app/shared/authentication/user/user-event.model';
import { MfUserEventService } from '@app/shared/authentication/user/user-event.service';
import { MfUserStorage } from '@app/shared/authentication/user/user-storage.service';
import { MfBankCustomizationService } from '@app/shared/bank-customization/bank-customization.service';
import { BankFeatures } from '@app/shared/bank-customization/bank-customization.type';
import { MfBreadcrumbItem } from '@app/shared/breadcrumbs/breadcrumbs-item.model';
import { MfBreadcrumbsService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { NAVIGATION } from '@app/shared/navigation/data/data.const';
import { ArrayUtil } from '@app/shared/util/array.util';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import {
  MfAppArea,
  MfCurrentNavigationItems,
  MfSidenavConfig,
  MfSidenavItemInterface,
  MfSidenavOptimizedTopLevelItem,
  MfSidenavTopLevelItemInterface,
} from './navigation.types';

const sidenavVisibilityBlacklist = [
  ['benutzerkonto'],
  ['bank-administration', 'pseudonymization-password'],
];

@Injectable({
  providedIn: 'root',
})
export class MfNavigationService {
  private enabledBankFeatures: BankFeatures[] = [];
  private userRoles: MfUserRolesEnum[] = [];
  private currentUrlSegments: string[] = [];

  private appArea?: MfAppArea;
  private readonly completeSidenavConfig: MfSidenavConfig = NAVIGATION;
  private filteredSidenavConfig?: MfSidenavConfig;

  private displayedSidenavItems$ = new BehaviorSubject<MfSidenavOptimizedTopLevelItem[]>([]);
  private currentNavigation$ = new BehaviorSubject<MfCurrentNavigationItems>([]);

  constructor(
    private userEventService: MfUserEventService,
    private userService: MfUserStorage,
    private bankCustomizationService: MfBankCustomizationService,
    private router: Router,
    private breadcrumbsService: MfBreadcrumbsService
  ) {
    this.bankCustomizationService.updates$.pipe(startWith([])).subscribe({
      next: () => {
        this.enabledBankFeatures = this.bankCustomizationService.getEnabledFeatures();
        this.updateNavigation(true);
      },
    });

    this.userEventService
      .onEvent()
      .pipe(
        map((event) => {
          switch (event.type) {
            case MfUserEventType.LOGIN:
            case MfUserEventType.UPDATED:
              return event.data;
          }

          return undefined;
        }),
        startWith(this.userService.getUser())
      )
      .subscribe((user) => {
        this.userRoles = user?.roles ?? [];

        this.updateNavigation(true);
      });

    this.router.events
      .pipe(
        filter((event: NavigationEnd | any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects),
        startWith(this.router.url),
        distinctUntilChanged()
      )
      .subscribe({
        next: (url: string) => {
          this.currentUrlSegments = this.splitUrl(url.split(/[?#]/)[0]);

          this.updateNavigation();
        },
      });
  }

  public get logo(): { imageUrl: string; imageAlt: string } {
    switch (this.appArea) {
      default:
      case MfAppArea.MARKETINGFABRIK:
        return {
          imageUrl: '/assets/images/logos/logo_marketingfabrik.svg',
          imageAlt: 'SHARED.MARKETING_FACTORY.TITLE',
        };
      case MfAppArea.MARKETPLACE:
        return {
          imageUrl: '/assets/images/logos/logo_marktplatz.svg',
          imageAlt: 'SHARED.MARKETPLACE.TITLE',
        };
    }
  }

  public get sidenavItems(): Observable<MfSidenavOptimizedTopLevelItem[]> {
    return this.displayedSidenavItems$
      .asObservable()
      .pipe(startWith(this.displayedSidenavItems$.value));
  }

  public get currentNavigation(): Observable<MfCurrentNavigationItems> {
    return this.currentNavigation$.asObservable().pipe(startWith(this.currentNavigation$.value));
  }

  public setAppArea(area?: MfAppArea) {
    if (this.appArea !== area) {
      this.appArea = area;
      this.updateNavigation(true);
    }
  }

  public splitUrl(link: string): string[] {
    return link.split('/').filter(ArrayUtil.truthyFilter);
  }

  public urlIsActive(urlSegments: string[]): boolean {
    return (
      !!this.currentUrlSegments.length &&
      ArrayUtil.beginsWithSameItems(urlSegments, this.currentUrlSegments)
    );
  }

  public itemIsVisible(
    item?: Pick<MfSidenavItemInterface, 'visibleForRoles' | 'requiredBankFeatures'>,
    userRoles?: MfUserRolesEnum[],
    bankFeatures?: BankFeatures[]
  ): boolean {
    if (!item) {
      return false;
    }

    if (
      ArrayUtil.isNonEmptyArray(item.visibleForRoles) &&
      !item.visibleForRoles.some((role) => userRoles?.includes(role))
    ) {
      return false;
    }

    if (
      ArrayUtil.isNonEmptyArray(item.requiredBankFeatures) &&
      !item.requiredBankFeatures.some((feature) => bankFeatures?.includes(feature))
    ) {
      return false;
    }

    return true;
  }

  public filterConfig(config: MfSidenavConfig): MfSidenavConfig {
    const filteredItems: MfSidenavConfig = {
      [MfAppArea.MARKETINGFABRIK]: [],
      [MfAppArea.MARKETPLACE]: [],
    };

    for (const [key, items] of Object.entries(config) as [
      keyof MfSidenavConfig,
      MfSidenavConfig[keyof MfSidenavConfig],
    ][]) {
      filteredItems[key] = this.filterItems(items);
    }

    return filteredItems;
  }

  public filterItems(items: MfSidenavTopLevelItemInterface[]): MfSidenavTopLevelItemInterface[] {
    const filteredItems: MfSidenavTopLevelItemInterface[] = [];

    for (const item of items) {
      // Check if first level is visible to user
      if (this.itemIsVisible(item, this.userRoles, this.enabledBankFeatures)) {
        const filteredSecondLevelItems: MfSidenavItemInterface[] = [];

        // Check if second level is visible to user
        for (const secondLevelItem of item.children) {
          if (this.itemIsVisible(secondLevelItem, this.userRoles, this.enabledBankFeatures)) {
            filteredSecondLevelItems.push(secondLevelItem);
          }
        }

        if (filteredSecondLevelItems.length) {
          filteredItems.push({
            ...item,
            children: filteredSecondLevelItems,
          });
        }
      }
    }

    return filteredItems;
  }

  public optimizeItems(items: MfSidenavTopLevelItemInterface[]): MfSidenavOptimizedTopLevelItem[] {
    const optimizedItems: MfSidenavOptimizedTopLevelItem[] = [];
    const activeLinkCandidates: { segmentCount: number; links: string[] } = {
      segmentCount: -1,
      links: [],
    };

    for (const item of items) {
      const optimizedItem: MfSidenavOptimizedTopLevelItem = {
        title: item.title,
        hidden: !!item.hideInNavigation,
        children: item.children.map((child) => {
          const linkSegments = this.splitUrl(child.link);

          // Collect all links that match the current url with the most segments
          if (this.urlIsActive(linkSegments)) {
            if (activeLinkCandidates.segmentCount < linkSegments.length) {
              activeLinkCandidates.segmentCount = linkSegments.length;
              activeLinkCandidates.links = [];
            }

            if (activeLinkCandidates.segmentCount === linkSegments.length) {
              activeLinkCandidates.links.push(child.link);
            }
          }

          return {
            title: child.title,
            link: child.link,
            linkSegments,
            active: false,
            hidden: !!child.hideInNavigation,
            children: child.children?.map((grandChild) => {
              const grandChildLink = child.link + grandChild.link;
              const grandChildLinkSegments = this.splitUrl(grandChildLink);

              return {
                title: grandChild.title,
                link: grandChildLink,
                linkSegments: grandChildLinkSegments,
              };
            }),
          };
        }),
      };

      optimizedItems.push(optimizedItem);
    }

    // Set all active links candidates to active
    if (activeLinkCandidates.links.length) {
      optimizedItems.forEach((item) => {
        item.children.forEach((child) => {
          if (activeLinkCandidates.links.includes(child.link)) {
            child.active = true;
          }
        });
      });
    }

    return optimizedItems;
  }

  private updateCurrentNavigation(optimizedItems: MfSidenavOptimizedTopLevelItem[]): void {
    let currentNavigation: MfCurrentNavigationItems = [];

    for (const item of optimizedItems) {
      for (const child of item.children) {
        if (child.active) {
          currentNavigation = [item, child];

          if (child.children) {
            for (const grandChild of child.children) {
              if (this.urlIsActive(grandChild.linkSegments)) {
                currentNavigation.push(grandChild);
              }
            }
          }

          break;
        }
      }
    }

    this.currentNavigation$.next(currentNavigation);
  }

  public updateNavigation(forceChange: boolean = false): void {
    // Only re-filter items when roles changed OR sidenav config has changed
    if (!this.filteredSidenavConfig || forceChange) {
      this.filteredSidenavConfig = this.filterConfig(this.completeSidenavConfig);
    }

    const filteredSidenavItems = this.appArea ? this.filteredSidenavConfig[this.appArea] : [];
    let optimizedItems: MfSidenavOptimizedTopLevelItem[] = [];
    if (
      !sidenavVisibilityBlacklist.some((blacklistItem) =>
        ArrayUtil.beginsWithSameItems(blacklistItem, this.currentUrlSegments)
      )
    ) {
      optimizedItems = this.optimizeItems(filteredSidenavItems);
    }

    this.updateCurrentNavigation(optimizedItems);
    this.updateBreadcrumbs();

    this.displayedSidenavItems$.next(optimizedItems);
  }

  private updateBreadcrumbs(): void {
    const breadcrumbs: MfBreadcrumbItem[] = [];

    for (const item of this.currentNavigation$.value) {
      if (item) {
        breadcrumbs.push({
          title: item.title,
          link: 'link' in item ? item.link : undefined,
        });
      }
    }

    this.breadcrumbsService.updateBreadcrumbs(breadcrumbs);
  }

  /**
   * Check if the user will be able to access this url
   *
   * @param url
   */
  public userCanAccessUrl(url: string): boolean {
    const urlSegments = this.splitUrl(url);

    if (this.filteredSidenavConfig) {
      for (const config of Object.values(this.filteredSidenavConfig)) {
        for (const item of config) {
          for (const child of item.children) {
            const childSegments = this.splitUrl(child.link);

            if (childSegments.length && ArrayUtil.beginsWithSameItems(childSegments, urlSegments)) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  // Returns the route to the top most navigation item to use as a default route
  public suggestDefaultRoute(appArea?: MfAppArea): string {
    if (appArea) {
      const items = this.filterItems(NAVIGATION[appArea]);

      const item = this.getFirstSuggestableItem(items);
      if (item) {
        return item.link;
      }
    } else if (this.filteredSidenavConfig) {
      for (const items of Object.values(this.filteredSidenavConfig)) {
        const item = this.getFirstSuggestableItem(items);
        if (item) {
          return item.link;
        }
      }
    }

    return '';
  }

  public suggestDefaultRouteCurrentArea(): string {
    return this.suggestDefaultRoute(this.appArea);
  }

  private getFirstSuggestableItem(
    items: MfSidenavTopLevelItemInterface[]
  ): MfSidenavItemInterface | undefined {
    for (const item of items) {
      const firstApplicableItem = item.children.find((child) => !child.doNotUseAsDefaultRoute);

      if (firstApplicableItem) {
        return firstApplicableItem;
      }
    }

    return undefined;
  }
}
