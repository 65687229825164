import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MfUserCsvExportDialogFormValue } from '@app/user/modals/user-csv-export-dialog/user-csv-export-dialog.component';
import {
  MfDataCreateUserInterface,
  MfDataRequestPasswordInterface,
  MfDataServiceUpdatePasswordModel,
  MfDataUserInterface,
} from '@shared/data/data.interface';
import { MfBankDataService } from '@shared/data/data.service';
import { MfMime } from '@shared/types/mime-types.enum';
import { toQueryParamsString } from '@shared/util/params.util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfUserDataService {
  constructor(
    private http: HttpClient,
    private bankDataService: MfBankDataService
  ) {}

  // istanbul ignore next
  public getMe(): Observable<MfDataUserInterface> {
    return this.http
      .get<MfDataUserInterface>('v1/users/me')
      .pipe(map((result: MfDataUserInterface) => this.parseUser(result)));
  }

  // istanbul ignore next
  public createUser(
    formModel: Partial<MfDataCreateUserInterface>
  ): Observable<MfDataUserInterface> {
    return this.http
      .post<MfDataUserInterface>('v1/users', formModel)
      .pipe(map((result: MfDataUserInterface) => this.parseUser(result)));
  }

  // istanbul ignore next
  public updateUser(
    userId: number,
    formModel: Partial<MfDataCreateUserInterface>
  ): Observable<MfDataUserInterface> {
    return this.http
      .patch<MfDataUserInterface>(`v1/users/${userId}`, formModel)
      .pipe(map((result: MfDataUserInterface) => this.parseUser(result)));
  }

  // istanbul ignore next
  public addExistingUserToPartner(userId: number): Observable<MfDataUserInterface> {
    return this.http
      .patch<MfDataUserInterface>(`v1/users/${userId}/add-existing-user-to-partner`, {})
      .pipe(map((result: MfDataUserInterface) => this.parseUser(result)));
  }

  // istanbul ignore next
  public transferCampaignForUserResponsible(
    currentUserResponsibleId: number,
    newUserResponsibleId: number
  ): Observable<void> {
    return this.http.patch<void>(`v1/users/${currentUserResponsibleId}/transfercampaigns`, {
      userResponsible: newUserResponsibleId,
    });
  }

  // istanbul ignore next
  public disableUser(userId: number): Observable<void> {
    return this.http.delete<void>(`v1/users/${userId}`);
  }

  // istanbul ignore next
  public unbanUser(userId: number): Observable<void> {
    return this.http.patch<void>(`v1/users/${userId}/unban`, {});
  }

  // istanbul ignore next
  public reactivateUser(userId: number): Observable<void> {
    return this.http.patch<void>(`v1/users/${userId}/reactivate`, {});
  }

  // istanbul ignore next
  public getUser(userId: number): Observable<MfDataUserInterface> {
    return this.http
      .get<MfDataUserInterface>(`v1/users/${userId}`)
      .pipe(map((result: MfDataUserInterface) => this.parseUser(result)));
  }

  // istanbul ignore next
  public getUsers(): Observable<MfDataUserInterface[]> {
    return this.http.get<MfDataUserInterface[]>('v1/users');
  }

  private parseUser(user: MfDataUserInterface): MfDataUserInterface {
    if (user?._embedded?.bank) {
      user._embedded.bank = this.bankDataService.parseBank(user._embedded.bank);
    }

    return user;
  }

  // istanbul ignore next
  public updatePassword(model: MfDataServiceUpdatePasswordModel): Observable<void> {
    return this.http.patch<void>('v1/users/password', model);
  }

  // istanbul ignore next
  public requestNewPassword(
    formModel: MfDataRequestPasswordInterface
  ): Observable<Record<any, any>> {
    return this.http.patch<MfDataRequestPasswordInterface>('v1/users/password/request', formModel);
  }

  // istanbul ignore next
  compareLast10Passwords(inputPassword: string, confirmationToken: string): Observable<void> {
    return this.http.post<void>('v1/users/password/recently-used', {
      password: inputPassword,
      confirmationToken,
    });
  }

  // istanbul ignore next
  canAddUserToPartner(email: string): Observable<MfDataUserInterface> {
    return this.http.post<MfDataUserInterface>('v1/users/can-add-to-partner', { email });
  }

  // istanbul ignore next
  resetPassword(userId: number) {
    return this.http.patch<void>(`v1/users/${userId}/password-reset`, {});
  }

  public downloadCsv(parameters: MfUserCsvExportDialogFormValue): Observable<HttpResponse<Blob>> {
    return this.http.get('v1/users/export.csv' + toQueryParamsString(parameters), {
      observe: 'response',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': MfMime.CSV,
      },
      responseType: 'blob',
    });
  }

  public downloadPartnerCsv(): Observable<HttpResponse<Blob>> {
    return this.http.get('v1/partner-users.csv', {
      observe: 'response',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': MfMime.CSV,
      },
      responseType: 'blob',
    });
  }
}
